import logo from './bnt.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          BnT Impact Windows & Doors
        </p>
        <p>
          <img src={logo} className="App-logo" alt="logo" />
        </p>
        <div>
          Visit our website for more details
        </div>
        <a
          className="App-link"
          href="https://bntwindowsanddoors.com/"
          rel="noopener noreferrer"
        >
           Website
        </a>
        <div>
          Dashboard Login
        </div>
        <a
          className="App-link"
          href="https://leads.bntwindows.com/"
          rel="noopener noreferrer"
        >
           Login
        </a>
      </header>
    </div>
  );
}

export default App;
